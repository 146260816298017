<template>
  <div>
    <b-container fluid>
      <b-row align-v="center">
        <b-col cols="2"/>
        <b-col>
          <Tile title="Datenschutzhinweis">
            <b-container fluid class="my-4">
              <b-row>
                <b-col cols="1"/>
                <b-col>
                  <VueMarkdownRender :source="source" :class="renderClasses"/>
                  <b-button @click="back" variant="primary" size="sm" class="mt-3">Zurück</b-button>
                </b-col>
                <b-col cols="1"/>
              </b-row>
            </b-container>
          </Tile>
        </b-col>
        <b-col cols="2"/>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VueMarkdownRender from "vue-markdown-render";
import markdown from "@/assets/markdown/datenschutz.md";
import Tile from "@/components/utility/Tile";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Datenschutz",
  components: {
    Tile,
    VueMarkdownRender,
  },
  data() {
    return {
      source: markdown
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    back() {
      this.$router.go(-1)
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    renderClasses() {
      try {
        if(this.userThemeLight) { return 'text-dark' }
        return 'text-white'
      }
      catch(error) {
        return 'text-white'
      }
    }
  },
  async created() {
    this.setLoadingState(false)
  }
}
</script>

<style lang="scss" scoped>
</style>
